// 1
import col1 from "../image/col1.png";
import col1_1 from "../image/col1-1.png";
import col1_2 from "../image/col1-2.png";
import col1_3 from "../image/col1-3.png";
import col1_4 from "../image/col1-4.png";
import col1_5 from "../image/col1-5.png";

// 2
import col2 from "../image/col2.png";
import col2_1 from "../image/col2-1.png";
import col2_2 from "../image/col2-2.png";
import col2_3 from "../image/col2-3.png";

// 3
import col3 from "../image/col3.png";
import col3_1 from "../image/col3-1.png";

// 4
import col4 from "../image/col4.png";

// 5
import col5 from "../image/col5.png";
import col5_1 from "../image/col5-1.png";
import col5_2 from "../image/col5-2.png";
import col5_3 from "../image/col5-3.png";

// 6
import col6 from "../image/col6.png";
import col6_1 from "../image/col6-1.png";
import col6_2 from "../image/col6-2.png";

// 7
import col7 from "../image/col7.png";
import col7_1 from "../image/col7-1.png";
import col7_2 from "../image/col7-2.png";
import col7_3 from "../image/col7-3.png";

// 8
import col8 from "../image/col8.png";
import col8_1 from "../image/col8-1.png";

export const productList = [
  {
    id: 1,
    title: "RIZZO Cat Tree",

    description: [
      "Naps are important but so is fun time, and there’s no reason a cat tree tower can’t provide both! Equally great for single or multi-cat households, the Rizzo comes with dual rest spots including a lower level plush perch and a luxuriously fluffy cat bed just one foot higher on the upper level, but it’s the bells and whistles of this gem that make it a feline favorite. Made of real tree branches, properly treated for pet safety and long term use indoors, this modern wood cat tree packs a playful punch with two sturdy scratching posts, a pom-pom toy, and a free-hanging 21.5-inch rope for irresistible fun.  For every Rizzo Cat Tree sold, a new tree is planted as a replacement.",
      "Available in Cotton White; Bullet Gray and Brown.",
      "Why you and your cats will love Rizzo: ",
      "Décor friendly design.  Minimalist, modern, and beautiful: You'll be as impressed as your cat every time you pass by it.",
      "Tree has a solid base to ensure stability - with a cozy cat cave",
      "2 comfortable resting spots",
      "Rizzo has playful add-ons to entertain her highness. Climb, claw, swipe, and swing for hours of fun.  Includes two dangling cat toys at various levels with bells.",
      "Easy to clean: Both removable basket cushions are made of comfortable faux fur plush fabric and are machine washable.",
      "This unique tree contains sustainably sourced real wood that is professionally crafted and naturally treated to last.  Each cat tree is one-of-a-kind and may have color and/or shape variances.  Your cats will own his/her own unique tree, unlike no other.",
      `Dimensions and Weight:  Base: 23" | Height: 32"  | Packaging weight: ~28 lbs.  Click the Dimension Diagram for full details.`,
      "Supports cats weighing up to 15 lbs",
      "Care: Wipe with damp cloth, vacuum with brush vacuum and remove stains with dry foam cleaning or spot clean with water. Do not use liquid detergents.   Cushions can be machine washed with NO DETERGENT and then quickly placed in the dryer (check every minute). Once dried, the fibers inside the cushion need to be manually stretched back to its original shape.",
      "Contact us for replacement parts.",
      "Please allow 7-14 days for tree delivery within the continental U.S.   Non-U.S. locations will have longer delivery time frames. Contact us if you would like to know the time frame for your location.",
      "Zed Candy Screamers Mega Sour Cherry Gum Pack",
      "Tango Shockers Apple Chew Bars",
      "Bazooka Rattlerz Fruity Bag",
      "Zed Candy Raspberry & Vanilla Ice Cream Jawbreaker 6 Pack",
      "Galaxy Smooth Caramel & Milk Chocolate Snack Bar",
      "Haribo Football Frenzy Limited Edition Bag",
      "July Box",
    ],
    quantity: 1,
    image: [col1, col1_1, col1_4, col1_5],
    price: "189.95",
    total: "189.95",
  },

  {
    id: 2,
    title:
      "Stress-Free Tilted, Raised Porcelain Cat Food Bowl, Water Bowl and Dining Tray Set - NEW!!!",
    description: [
      "Give your cat the gift of stress free dining!",
      "Our Porcelain Raised Dining Set will have your cat dining in ultimate comfort.",
      "Here's why you and your cat will love our porcelain dining bowl set:",
      "A chic and stylish design in white that complements any décor.",
      "Unlike traditional food bowls, the tilted lip of the tilted, raised dining bowl helps your cat eat with less neck fatigue.  Older cats especially benefit from this feature. ",
      "By raising the food to his level instead of forcing him to bend down, it makes for easier swallowing and smoother digestion.",
      "The raised, tilted dining bowl’s anti-spill wide lip also reduces whisker fatigue and makes it easier for flat-faced cats to eat every last bite.",
      "Made from non-absorbent porcelain that is FDA-approved.",
      "Dishwasher and microwave safe.",
      "Rubberized bottom on the bowls prevents bowl movement",
      "Porcelain tray will fit most standard food and water bowls.",
      "Dimensions: ",
      'Raised, tilted bowl: 6.1" l x 5.7" w x 5.3" h',
      'Raised water bowl:   5.2" W  2.9" H',
      'Porcelain dining tray:  13.8" L x 8" W x 0.75 " H.',
      "Weight:  5.0 lbs",
    ],
    quantity: 1,
    image: [col2, col2_1, col2_2, col2_3],
    price: "82.80",
    total: "82.80",
  },
  {
    id: 3,
    title: "Cat Bike Bell",
    description: [
      "The simple brass bell with cat-titude!",
      "Stop traffic with this adorable Cat Bike Bell!",
      "There’s no substitute for a good old-fashioned bicycle bell when you really want other people to get out of your way. The Cat Bike Bell uses centuries old, tried and trusted technology - A compact brass bell with a spring thumb lever. Good and loud and guaranteed to startle even the most oblivious dog walker. Warn other road users before cat-astrophe strikes!",
      "Simply unscrew, attach, tighten and ride!",
      "A great gift for feline obsessed cyclists - you know who you are!",
    ],
    quantity: 1,
    image: [col3, col3_1],
    price: "16.95",
    total: "16.95",
  },
  {
    id: 4,
    title: "'Laurel Burch™ Fantasticats Overnighter Travel Bag",
    description: [
      "Accessorize in Style with this Striking Laurel Burch Travel Bag!",
      "Show your love of felines and your good taste with this generously sized travel bag. Embellished with a matching Fantasticats decorative tag.  Colorful, yet versatile design has shimmering gold accents throughout!",
      `Why you'll love this travel bag:`,
      "Durable, canvas fabric exterior, vinyl interior",
      "Easy maintenance, wipe clean with a damp cloth.",
      "Interior zippered compartment for toiletries or valuables",
      "Interior cell phone compartment",
      "Two generously sized side pockets",
      "Metallic cat designed zipper tag across the top",
      "Six sturdy metal feet to protect the underside",
      "Durable, turquoise shoulder strap, double stitched at the top for extra strength",
      'Dimensions: 21"L x 8"D x 15"H',
    ],
    quantity: 1,
    image: [col4, col4],
    price: "45.95",
    total: "45.95",
  },
  {
    id: 5,
    title: "Fling-ama-String™ Interactive Cat Toy",
    description: [
      `If cats had money, they'd buy one of these!!!`,
      `Does your cat fit into one of these "Cat"-egories? Fling-ama-string™ is perfect for every type of cat!`,
      "The Hunter: It brings out your natural predatory instinct and improves paw-eye coordination!",
      `Flabby Tabby: It's like having your own purrsonal trainer! `,
      `Sleep-Obsessed: It's been known to reduce sleep time to only 18 hours a day!`,
      "Lazy Bones: The best positions for playing with fling-ama-string is lying down!",
      "Long in the Fang: It will help bring our your inner kitten.",
      `Fling-ama-string™ hangs on any standard door knob. It is battery-operated. One side of a long string is sewn to a flat elastic belt which is constantly rotating. The string flings in and out for kitty's non-stop amusement. The toy has 2 speeds to satisfy the frisky or faint feline. `,
      "Order 2 and save! Click the drop down arrow and select a 2 Pack to get the discount.",
      "Why you and your cat will love Fling-ama-String:",
      "No assembly required",
      "Simple instructions included for putting the Fling-ama-string in action",
      "Hangs on any standard door knob",
      "Improves paw-eye coordination",
      "Battery operated (uses 3 AA batteries not included)",
      "Ideal for exercise",
      "Keeps your cat busy and happy",
    ],
    quantity: 1,
    image: [col5, col5_1, col5_2, col5_3],
    price: "29.95",
    total: "29.95",
  },
  {
    id: 6,
    title: "Refined Feline Deluxe Litter Box Cabinet - Extra Large Size",
    description: [
      "Our Deluxe Litter Boxes in all finishes are now in stock. There is fast sell-through on this item.  We will accept preorders on any out of stock items and will notify you when your order is shipped.  Shipping timeframe is approximately 10-14 days from order date.",
      `No one will suspect this beautiful wood cabinet contains an unsightly, odorous cat litter pan. Both sturdy and functional, the Refined Litter Box from The Refined Feline keeps a cat litter pan out of sight all while matching your home's decor. More that just a cabinet, the Refined Litter Box features an internal plastic liner to contain stray litter, rear slots that allow for odor absorbing carbon sheets, and a drawer to store litter accessories.  Fits Large and Jumbo size litter pans.`,
      "Extra large sized Litter Box accommodates any sized cat - even those adorable Maine Coons and larger cats.",
      "Features:",
      "Constructed in sold wood and ply with an oak veneer that repels moisture",
      "Spacious interior allows for any sized litter pans including automated litter pans  (back of unit has a hole to accommodate a cord). ",
      "Contains internal plastic litter liner help contain stray litter for easier clean up",
      "Top storage drawer holds your cat litter box supplies out of sight",
      "Reversible walls for left or right entry, giving you the flexibility of placing this litter box cabinet anywhere",
      "Rear slots to hold any universal odor absorbing carbon filters",
      "Available in Espresso, Mahogany and Smoke",
      "Coordinating wooden litter catch is sold here.",
      "Do you want to change the look of your front panels?  Front panels slide out for replacement and are available in three styles:  Shaker; Cottage; and Farmhouse.  Click here to view and place your order.",
      'Dimensions:  33.5" W x 28.5" H x 23" D',
      'Entry opening:  9.5" W x 10.5" H',
      "Product Weight:  46 lbs.",
      "Assembly required.",
    ],
    quantity: 1,
    image: [col6, col6_1, col6_2],
    price: "269.95",
    total: "269.95",
  },
  {
    id: 7,
    title: "Cat Hair Removing Massaging Shell Comb",
    description: [
      "Grooming your cat can either be a real hassle or a pleasant experience for you both.",
      "This award winning 2-in-1 shell comb removes hair and massages your cat in the most gentle way possible, leaving healthy, shiny fur, free from tangles and knots. ",
      `With an elastic ergonomic design that doesn't scratch or irritate your cat's skin, he'll always be ready for a comb-out and loving it!  And he'll come back for more!`,
    ],
    quantity: 1,
    image: [col7, col7_1, col7_2, col7_3],
    price: "14.95",
    total: "14.95",
  },
  {
    id: 8,
    title: "Cat Nap Heart Embroidered Hand Towel",
    description: [
      "Any self-respecting cat purrson will want this embroidered towel in their bathroom!",
      "Cats settling down for a long nap naturally arrange themselves in the most comfortable napping position.  These cats did just that -- they happily snooze in a heart shaped design!",
      `These soft, 100% cotton white hand towels measure 16" wide, 30" long. `,
      "Beautifully embroidered sleeping cats in heart shape design. ",
      `5" finished border on both ends of towel. `,
      "Machine washable, read label.",
    ],
    quantity: 1,
    image: [col8, col8_1],
    price: "15.95",
    total: "15.95",
  },
];

export const getProductDetailById = (id) => {
  for (let i = 0; i < productList.length; i++) {
    if (productList[i].id === id) {
      return productList[i];
    }
  }
};

export const getRandomElements = (id) => {
  const result = [];
  const arrayCopy = [...productList]; // 创建数组的副本，以避免修改原始数组

  for (let i = 0; i < 4; i++) {
    let randomIndex;

    // 确保随机索引不与给定的 id 相同
    do {
      randomIndex = Math.floor(Math.random() * arrayCopy.length);
    } while (productList[randomIndex].id === id);

    const element = arrayCopy.splice(randomIndex, 1)[0];
    result.push(element);
  }

  return result;
};
