import React, { Component } from "react";
import "../style/home.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { productList } from "../apis/GetProduct";
import ShoppingCart from "../icon/ShoppingCart";
import tastydaily from "../image/tastydaily.svg";
import tastydaily2 from "../image/tastydaily2.svg";
import tastydaily3 from "../image/tastydaily3.svg";
import tastydaily4 from "../image/tastydaily4.svg";
import sec1 from "../image/sec1.png";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.routePage = this.routePage.bind(this);
    this.hoverChange = this.hoverChange.bind(this);
    this.routeProductDetail = this.routeProductDetail.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    productList.map((item) => {
      item.isHover = false;
    });
    this.setState({ productList });
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  hoverChange(index) {
    let { productList } = this.state;
    productList[index].isHover = !productList[index].isHover;
    this.setState({ productList });
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="home-page">
          <div className="sec1">
            <img src={sec1} alt="" />
          </div>
          <div className="sec2">
            <div className="title">Five Star Products5 Stars</div>
            <div className="row">
              {productList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onMouseEnter={() => this.hoverChange(index)}
                    onMouseLeave={() => this.hoverChange(index)}
                    onClick={() => this.routeProductDetail(item.id)}
                  >
                    <div className="img-box">
                      {item.isHover ? (
                        <img src={item.image[1]} lt="" />
                      ) : (
                        <img src={item.image[0]} lt="" />
                      )}
                    </div>
                    <div className="txt">
                      <div className="title">{item.title}</div>
                      <div className="tool">
                        <div className="price">${item.price}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sec3">
            <div className="col">
              <div className="img">
                <img src={tastydaily} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Fast Dispatch</div>
                <div className="p">Dispatch your order within 24 hours</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily2} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Freshest Products</div>
                <div className="p">Freshly made stock every day</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily3} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Friendly Support</div>
                <div className="p">Email us if you have any queries</div>
              </div>
            </div>
            <div className="col">
              <div className="img">
                <img src={tastydaily4} alt="" />
              </div>
              <div className="txt-box">
                <div className="title">Secure Payments</div>
                <div className="p">We use safest payment technologies</div>
              </div>
            </div>
          </div>
          <div className="sec4">
            <Swiper
              spaceBetween={50} // 每个slide之间的间距
              slidesPerView={1} // 每次显示的slide数量
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              loop={true}
              autoplay={{ delay: 300 }}
            >
              <SwiperSlide>
                <div className="p">
                  Great selection of difficult to find items. Fast, safe
                  shipping too.
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p">
                  The order arrived so fast & I could tell it was packed with
                  care. I loved the personalized note on the receipt.
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p">
                  I bought these figurines as Christmas gifts for a couple cat
                  lovers in my life. They were thrilled with them! The colors &
                  attention to detail were amazing. Thanks My3Cats for another
                  great shopping experience.
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p">Excellent customer service and support.</div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="p">
                  Kitty Condor is exactly what I had been looking for! It was
                  out of stock in every other store that I had checked. Very
                  satisfied with my purchase! Also arrived quickly
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="sec5">
            <div className="title">Unique Cat Accessories</div>
            <p>You've landed on a very special cat shopping site. </p>
            <p>
              We site specializes in providing ONLY THE BEST quality cat
              products in the industry that provide solutions for your cats’
              particular needs. Among our unique cat products and cat
              accessories are mentally stimulating catnip toys, cat teasers and
              puzzle games. Try our decor friendly cat feeders, cat fountains,
              cat furniture and loungers, all top of the line, cat tested cat
              products. Cat lovers - accessorize your wardrobe and your home
              with our eye-catching Laurel Burch wearable cat-themed art and
              home gift collection. Don’t settle for just any cat gifts—shop our
              unique cat products for the best in the industry!{" "}
            </p>
            <p>
              {" "}
              Save time and money, have your gift order wrapped! Simply request
              our signature gift wrap and if desired, add a special card message
              in the customer note box located on your order.{" "}
            </p>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Home;
