import React, { Component } from "react";
import "../style/productDetail.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import { getProductDetailById, getRandomElements } from "../apis/GetProduct";
import Truck from "../icon/Truck";
import Time from "../icon/Time";
import Number from "./common/Number";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      isActive: 0,
      randomList: [],
    };
    this.clickChangeImage = this.clickChangeImage.bind(this);
    this.clickAddToCart = this.clickAddToCart.bind(this);
    this.routePage = this.routePage.bind(this);
  }
  headerRef = React.createRef();
  numberRef = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0);
    let { location } = this.props;
    let id = location.pathname.replace("/ProductDetail/", "");
    let product = getProductDetailById(parseInt(id));
    let randomList = getRandomElements(id);
    console.log(randomList);
    this.setState({ product, randomList });
  }

  clickChangeImage(index) {
    this.setState({ isActive: index });
  }

  clickAddToCart(product) {
    let isProductInCart = false;
    let quantity = 0;
    let num = 0;
    if (this.numberRef) {
      product.quantity = this.numberRef.current.state.num;
    }

    if (this.headerRef) {
      let cartList = this.headerRef.current.state.cartList;

      if (cartList.length <= 0) {
        cartList.push(product);
      } else {
        for (let i = 0; i < cartList.length; i++) {
          if (cartList[i].id === product.id) {
            cartList[i].quantity += product.quantity;
            cartList[i].total = (
              cartList[i].quantity * parseFloat(product.price)
            ).toFixed(2);
            isProductInCart = true;
            break;
          }
        }

        if (!isProductInCart) {
          cartList.push(product);
        }
      }

      cartList.forEach((item) => {
        quantity += item.quantity;
      });

      this.headerRef.current.setState({ cartList, quantity });
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
      this.routePage('cart');
    }

  }

  hoverChange(index) {
    let { randomList } = this.state;
    randomList[index].isHover = !randomList[index].isHover;
    this.setState({ randomList });
  }

  routeProductDetail(id) {
    let { history } = this.props;
    history.push(`/ProductDetail/${id}`);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }


  render() {
    let { product, isActive, randomList } = this.state;

    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="productDetail-page">
          <div className="productDetail">
            <div className="row1">
              <div className="left-box">
                <div className="big-img">
                  <img src={product.image && product.image[isActive]} alt="" />
                </div>
                <div className="little-img">
                  {product.image &&
                    product.image.map((item, index) => {
                      return (
                        <div
                          className={`item ${
                            isActive === index ? "active" : ""
                          }`}
                          key={index}
                          onClick={() => {
                            this.clickChangeImage(index);
                          }}
                        >
                          <img src={item} alt="" />
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="right-box">
                <div className="title">{product.title}</div>
                <div className="price">${product.price}</div>
                {/* <div className="feature">
                  <div className="feature-item">
                    <Truck size={20} color="#00aa63" className="icon" /> Order
                    Now For Fast Dispatch
                  </div>
                  <div className="feature-item">
                    <Time size={20} color="#00aa63" className="icon" />
                    Fast Dispatch To Guarantee Fast Delivery Times And Fresh
                    Products
                  </div>
                </div> */}
                <div className="descrption">
                  {product.description &&
                    product.description.map((item, index) => {
                      return (
                        <div className="li" key={index}>
                          {item}
                        </div>
                      );
                    })}
                </div>
                <div className="btn-row">
                  <Number {...this.props} ref={this.numberRef} />
                  <div
                    className="addToCart"
                    onClick={() => this.clickAddToCart(product)}
                  >
                    ADD TO CART
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relate-row">
            <div className="row">
              {randomList.map((item, index) => {
                return (
                  <div
                    className="col"
                    key={index}
                    onMouseEnter={() => this.hoverChange(index)}
                    onMouseLeave={() => this.hoverChange(index)}
                    onClick={() => this.routeProductDetail(item.id)}
                  >
                    <div className="img-box">
                      {item.isHover ? (
                        <img src={item.image[1]} lt="" />
                      ) : (
                        <img src={item.image[0]} lt="" />
                      )}
                    </div>
                    <div className="txt">
                      <div className="title">{item.title}</div>
                      <div className="tool">
                        <div className="price">${item.price}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default ProductDetail;
