import React, { Component } from "react";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import "../style/cart.scss";
import Number from "./common/Number";
import CloseIcon from "../icon/CloseIcon";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      total: 0,
      quantity: 0,
    };
    this.routeCheckout = this.routeCheckout.bind(this);
    this.removeProduct = this.removeProduct.bind(this);
    this.routeHome = this.routeHome.bind(this);
  }

  numberRef = React.createRef();
  headerRef = React.createRef();

  componentDidMount() {
    if (localStorage.getItem("cart")) {
      let cartList = JSON.parse(localStorage.getItem("cart"));
      let total = 0;
      cartList.map((item) => {
        total += parseInt(item.price);
      });
      this.setState({ cartList, total: total.toFixed(2) });
    }
  }

  callBackNum(num, productIndex) {
    let { cartList } = this.state;
    let quantity = 0;
    let total = 0;
    cartList.map((item, index) => {
      if (index === productIndex) {
        item.quantity = num;
        item.total = (num * parseInt(item.price)).toFixed(2);
      }
      quantity += item.quantity;
      total += parseFloat(item.total);
    });
    this.setState({ cartList, quantity, total: total.toFixed(2) }, () => {
      if (this.headerRef) {
        this.headerRef.current.setState({
          cartList,
          quantity,
        });
      }
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
    });
  }

  removeProduct(num, productTotal, index) {
    let { cartList, quantity, total } = this.state;
    cartList.splice(index, 1);
    quantity -= num;
    total -= parseFloat(productTotal);
    this.setState({ cartList, quantity, total: total.toFixed(2) }, () => {
      this.headerRef.current.setState({ cartList, quantity });
      localStorage.setItem("cart", JSON.stringify(cartList));
      localStorage.setItem("quantity", JSON.stringify(quantity));
    });
  }

  routeCheckout() {
    let { history } = this.props;
    history.push("/Checkout");
  }

  routeHome() {
    let { history } = this.props;
    history.push("/");
  }

  render() {
    const { cartList, total } = this.state;
    return (
      <React.Fragment>
        <HeaderBar {...this.props} ref={this.headerRef} />
        <div className="cart-page">
          <div className="title-head">Cart</div>
          {cartList.length > 0 ? (
            <div className="cart-content">
              <div className="left">
                <div className="head-row">
                  <div className="title">PRODUCT</div>
                  <div className="quantity">QUANTITY</div>
                  <div className="subtotal">SUBTOTAL</div>
                </div>
                {cartList.map((item, index) => {
                  return (
                    <div className="product-row" key={index}>
                      <div className="title">
                        <div className="remove">
                          <CloseIcon
                            size={20}
                            onClick={() =>
                              this.removeProduct(
                                item.quantity,
                                item.total,
                                index
                              )
                            }
                          />
                        </div>
                        <div className="img-box">
                          <img src={item.image[0]} alt="" />
                        </div>
                        <div className="product-title">{item.title}</div>
                      </div>
                      <div className="quantity">
                        <Number
                          {...this.props}
                          num={item.quantity}
                          ref={this.numberRef}
                          receiveNum={(num) => this.callBackNum(num, index)}
                        />
                      </div>
                      <div className="subtotal">${item.total}</div>
                    </div>
                  );
                })}
              </div>
              <div className="right">
                <div className="right-row">
                  <div className="key">Subtotal</div>
                  <div className="value">${total}</div>
                </div>
                <div className="right-row">
                  <div className="key">Total</div>
                  <div className="value">${total}</div>
                </div>
                <div className="check" onClick={this.routeCheckout}>
                  Checkout
                </div>
              </div>
            </div>
          ) : (
            <div className="noContent">
              <p>YOUR CART IS EMPTY</p>
              <div className="btn" onClick={this.routeHome}>
                Continue Shopping
              </div>
            </div>
          )}
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cart;
