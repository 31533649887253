import React, { Component } from "react";
import "../../style/common/headerBar.scss";
import Star from "../../icon/Star";
import HalfStar from "../../icon/HalfStar";
import ShoppingCart from "../../icon/ShoppingCart";
import logo from "../../image/my-three-cats.avif";
import Check from "../../icon/Check";
import header_logo from "../../image/header-logo.png";
import header_logo2 from "../../image/header-logo2.png";
import ShopBasket from "../../icon/ShopBasket";
import Shoping from "../../image/shoping.svg";

class HeaderBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartList: [],
      quantity: 0,
    };
    this.routePage = this.routePage.bind(this);
  }
  componentDidMount() {
    if (localStorage.getItem("cart")) {
      let cartList = JSON.parse(localStorage.getItem("cart"));
      let quantity = 0;
      cartList.map((item) => {
        quantity += item.quantity;
      });
      this.setState({ cartList, quantity });
    }
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "pricing":
        history.push("/Pricing");
        break;
      case "contact":
        history.push("/Contact");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "cart":
        history.push("/Cart");
        break;
      default:
        break;
    }
  }

  render() {
    const { quantity } = this.state;
    return (
      <React.Fragment>
        <div className="header">
          <div className="left">
            <div className="logo" onClick={() => this.routePage("home")}>
              <img src={logo} alt="" />
            </div>
            <div className="tab">
              <div className="li" onClick={() => this.routePage("about")}>
                About us
              </div>
              <div className="li" onClick={() => this.routePage("contact")}>
                Contact us
              </div>
            </div>
          </div>
          <div className="right">
            YOUR ACCOUNT
            <div className="shop" onClick={()=>this.routePage('cart')}>
              <img src={Shoping} alt="" />
              <div className="num">{quantity}</div>
            </div>{" "}
            YOUR CART
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HeaderBar;
