import React, { Component } from "react";
import "../../style/common/footerBar.scss";
import Facebook from "../../icon/Facebook";
import Instagram from "../../icon/Instagram";
import Pinterest from "../../icon/Pinterest";

import logo from "../../image/my-three-cats.avif";
import TikTok from "../../icon/TikTok";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "about":
        history.push("/About");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footerBar">
          <div className="center">
            <div className="img-box">
              <img src={logo} alt="" />
            </div>

            <div className="left">
              <li onClick={() => this.routePage("about")}>About Us</li>
              <li onClick={() => this.routePage("contact")}>Contact Us</li>
              {/* <li onClick={() => this.routePage("privacy")}>Privacy Policy</li> */}
            </div>
            <div className="icon-row">
              <Facebook size={20} color="#fff" className="icon" />
              <Instagram size={20} color="#fff" className="icon" />
              <TikTok size={20} color="#fff" className="icon" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
